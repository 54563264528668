.tnc-nav {
  width: 30%;
  padding: 16px;
  margin: 8px;
  line-height: 2;
  align-self: flex-start;
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 48px;
  max-height: calc(100vh - 70px);
  overflow: auto;
}

.tnc-nav li.active>a {
  color: #1e90ff;
}

.tnc-nav li>a:hover {
  color: #1e90ff;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

th,
td {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}
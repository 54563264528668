@tailwind base;
@tailwind components;
@tailwind utilities;

html,
  body {
    scroll-behavior: smooth;
    padding: 0;
    margin: 0;
    font-family: Roboto, sans-serif;
  }